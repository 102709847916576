<template>
    <div class="container my-4 px-2">
        <header class="mb-3">
            <router-link class="brand-logo" :to="{ name: 'inicio-home' }">
                <b-img fluid :src="appLogo" alt="logo" />
            </router-link>
        </header>
        <section class="container-main">

            <div class="container-local">
                <h1>Política de Privacidad de HOMIUP</h1>
                <p><strong>Última actualización: 10-10-2023</strong></p>
                <p>La presente Política de Privacidad tiene como objetivo informarte sobre cómo HOMIUP recopila, utiliza y
                    comparte tus datos personales y confidenciales. Es importante que leas y comprendas esta política antes
                    de utilizar nuestra aplicación. Al instalar y usar HOMIUP, aceptas expresamente los términos y
                    condiciones de esta política.</p>

                <h2>Recopilación de Datos</h2>
                <p>HOMIUP recopila, transmite, sincroniza y almacena ciertos tipos de datos con el fin de habilitar las
                    siguientes funciones:</p>
                <ul>
                    <li>Lista de contactos para registrar clientes de una forma rápida, con el fin de mejorar la venta de
                        inmuebles del agente.</li>
                </ul>
                <p>La recopilación de datos es esencial para proporcionar una experiencia óptima en nuestra aplicación.</p>

                <h2>Uso de Datos</h2>
                <p>Los datos recopilados por HOMIUP se utilizan exclusivamente para los fines mencionados anteriormente. No
                    compartimos tus datos personales y confidenciales con terceros sin tu consentimiento expreso.</p>

                <h2>Divulgación Destacada</h2>
                <p>Esta divulgación destacada se encuentra dentro de la aplicación y se muestra durante el uso normal de la
                    misma. No requerirás navegar a un menú o configuración para acceder a esta información.</p>

                <h2>Solicitud de Consentimiento del Usuario</h2>
                <p>Para garantizar la transparencia y el consentimiento inequívoco, requiere una acción afirmativa del
                    usuario para recopilar o acceder a datos personales y confidenciales. Debes tocar para aceptar o marcar
                    una casilla de verificación claramente visible.</p>
                <p>La navegación fuera de esta divulgación, incluyendo tocar o presionar el botón Atrás o Inicio, no se
                    interpretará como consentimiento. No utilizamos mensajes que se descarten automáticamente o que caduquen
                    como medio para obtener tu consentimiento. Debes otorgar tu consentimiento antes de que HOMIUP pueda
                    comenzar a recopilar o acceder a tus datos personales y confidenciales.</p>

                <h2>Cambios en la Política de Privacidad</h2>
                <p>Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Cualquier cambio
                    será notificado a los usuarios a través de una actualización en la aplicación.</p>

                <h2>Contacto</h2>
                <p>Si tienes alguna pregunta o inquietud sobre esta política, no dudes en contactarnos en Calle 5 85-29 o <a
                        href="mailto:contacto@HOMIUP.co">contacto@HOMIUP.co</a>.</p>
            </div>

        </section>


    </div>
</template>

<script>
import { $themeConfig } from '@themeConfig';
export default {

    data() {
        return {
            appLogo: null
        }
    },

    created() {
        const { appName, appLogoImage } = $themeConfig.app;
        this.appLogo = appLogoImage;

    },

}
</script>

<style scoped>
.container-main {
    position: relative;
}
.container-local {
            max-width: 600px;
            margin: 0 auto;
            padding: 20px;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
        }

.container_text {
    position: absolute;
    top: 0;
    left: 0;
}

.brand-logo img {
    width: 100%;
    max-width: 210px;
}

.title {
    text-align: center;
    font-size: bold;
}

.subtitle {
    text-align: left;
    font-weight: bold;
}

.text {
    text-align: justify;
    color: black;
}</style>
